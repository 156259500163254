<template>
	<div class="pages-index">
		<!-- 顶部Banner -->
		<div class="carousel-banner">
			<swiper :options="swiperOptionBanner" v-if="chooseTab<1" key="0">
				<swiper-slide v-for="(item,index) in swiperList1" :key="index">
					<div class="top-css">
						<img class="img-bg img-pc" :src="require('@/assets/image/hzal2-2/hzal_bg.png')" />
						<img class="img-ph" :src="require('@/assets/image/hzal2-2/hzal_bg_ph.png')" />
						<div class="top-css00">
							<div class="c1">
								<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 5, offset: 2 }"
									:lg="{ span: 3, offset: 0 }">
									<div class="c1-left">
										<div class="table">
											<div id="div" class="choosev" :class="item.ischose?'choosev2':''"
												v-for="(item, index) in itemData" :key="index"
												@click="menuClick(index)">
												<span>{{item.name}}</span>
											</div>
										</div>
									</div>
								</el-col>
								<el-col class="" :xs="{ span: 24 }" :sm="{ span: 15 }" :md="{ span: 10, offset: 2 }"
									:lg="{ span: 20, offset: 0 }">
									<div class="swiper-box">
										<!-- slides -->
										<!-- <a href="https://w.url.cn/s/A0Rz4Kv" target="_blank"> -->
										<div class="c1-right wow slideInUp" data-wow-delay="0s">
											<div class="r-css">
												<img :src="item.img" />
												<div class="text-box">
													<div class="title">{{item.title}}</div>
													<br />
													<p>项目简介</p>
													<p>{{item.p1}}
													</p>
													<br />
													<p>主要功能</p>
													<p>{{item.p2}}
													</p>
													<img v-if="item.imgIcon" style="margin-top: 20px;"
														:src="item.imgIcon" />

												</div>

											</div>
										</div>
										<!-- </a> -->
										<!-- Optional controls -->
										<!-- <div class="swiper-pagination swiper-card-pagination" slot="pagination"></div> -->
										<div class="top1 swiper-button-prev swiper-card-button-prev swiper-button-white"
											slot="button-prev"></div>
										<div class="top1 swiper-button-next swiper-card-button-next swiper-button-white"
											slot="button-next"></div>
									</div>

								</el-col>

							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<!-- ///////////////////////////////////////////////////////////// -->
			<swiper :options="swiperOptionBanner" v-if="chooseTab==1" key="1">
				<swiper-slide v-for="(item,index) in swiperList2" :key="index">
					<div class="top-css1">
						<img class="img-bg" :src="item.bg" />
						<!-- <img class="img-ph" :src="item.bg" /> -->
						<div class="top-css00">
							<div class="c1">
								<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 5, offset: 2 }"
									:lg="{ span: 3, offset: 0 }">
									<div class="c1-left">
										<div class="table">
											<div id="div" class="choosev" :class="item.ischose?'choosev2':''"
												v-for="(item, index) in itemData" :key="index"
												@click="menuClick(index)">
												<span>{{item.name}}</span>
											</div>
										</div>
									</div>
								</el-col>
								<el-col class="" :xs="{ span: 24 }" :sm="{ span: 15 }" :md="{ span: 10, offset: 2 }"
									:lg="{ span: 20, offset: 0 }">
									<div class="swiper-box">
										<!-- slides -->
										<!-- <a href="https://w.url.cn/s/A0Rz4Kv" target="_blank"> -->
										<div class="c1-right wow slideInUp" data-wow-delay="0s">
											<div class="r-css">
												<img :src="item.img" />
												<div class="text-box">
													<div class="title">{{item.title}}</div>
													<br />
													<p>项目简介</p>
													<p>{{item.p1}}
													</p>
													<br />
													<p>主要功能</p>
													<p>{{item.p2}}
													</p>
													<img v-if="item.imgIcon" style="margin-top: 20px;"
														:src="item.imgIcon" />

												</div>

											</div>
										</div>
										<!-- </a> -->
										<!-- Optional controls -->
										<!-- <div class="swiper-pagination swiper-card-pagination" slot="pagination"></div> -->
										<div class="top2 swiper-button-prev swiper-card-button-prev swiper-button-white"
											slot="button-prev"></div>
										<div class="top2 swiper-button-next swiper-card-button-next swiper-button-white"
											slot="button-next"></div>
									</div>

								</el-col>

							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<!-- ///////////////////////////////////////////////////////////// -->
			<swiper :options="swiperOptionBanner" v-if="chooseTab==2" key="2">
				<swiper-slide v-for="(item,index) in swiperList3" :key="index">
					<div class="top-css">
						<img class="img-bg img-pc" :src="require('@/assets/image/hzal2-2/hzal_bg.png')" />
						<img class="img-ph" :src="require('@/assets/image/hzal2-2/hzal_bg_ph.png')" />
						<div class="top-css00">
							<div class="c1">
								<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 5, offset: 2 }"
									:lg="{ span: 3, offset: 0 }">
									<div class="c1-left">
										<div class="table">
											<div id="div" class="choosev" :class="item.ischose?'choosev2':''"
												v-for="(item, index) in itemData" :key="index"
												@click="menuClick(index)">
												<span>{{item.name}}</span>
											</div>
										</div>
									</div>
								</el-col>
								<el-col class="" :xs="{ span: 24 }" :sm="{ span: 15 }" :md="{ span: 10, offset: 2 }"
									:lg="{ span: 20, offset: 0 }">
									<div class="swiper-box">
										<!-- slides -->
										<!-- <a href="https://w.url.cn/s/A0Rz4Kv" target="_blank"> -->
										<div class="c1-right wow slideInUp" data-wow-delay="0s">
											<div class="r-css">
												<img :src="item.img" />
												<div class="text-box">
													<div class="title">{{item.title}}</div>
													<br />
													<p>项目简介</p>
													<p>{{item.p1}}
													</p>
													<br />
													<p>主要功能</p>
													<p>{{item.p2}}
													</p>
												</div>

											</div>
										</div>
										<!-- </a> -->
										<!-- Optional controls -->
										<!-- <div class="swiper-pagination swiper-card-pagination" slot="pagination"></div> -->
										<div class="top3 swiper-button-prev swiper-card-button-prev swiper-button-white"
											slot="button-prev"></div>
										<div class="top3 swiper-button-next swiper-card-button-next swiper-button-white"
											slot="button-next"></div>
									</div>

								</el-col>

							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<!-- ///////////////////////////////////////////////////////////// -->

			<swiper :options="swiperOptionBanner" v-if="chooseTab==3" key="3">
				<swiper-slide v-for="(item,index) in swiperList4" :key="index">
					<div class="top-css1">
						<img class="img-bg" :src="item.bg" />
						<div class="top-css00">
							<div class="c1">
								<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 5, offset: 2 }"
									:lg="{ span: 3, offset: 0 }">
									<div class="c1-left">
										<div class="table">
											<div id="div" class="choosev" :class="item.ischose?'choosev2':''"
												v-for="(item, index) in itemData" :key="index"
												@click="menuClick(index)">
												<span>{{item.name}}</span>
											</div>
										</div>
									</div>
								</el-col>
								<el-col class="" :xs="{ span: 24 }" :sm="{ span: 15 }" :md="{ span: 10, offset: 2 }"
									:lg="{ span: 20, offset: 0 }">
									<div class="swiper-box">
										<!-- slides -->
										<!-- <a href="https://w.url.cn/s/A0Rz4Kv" target="_blank"> -->
										<div class="c1-right wow slideInUp" data-wow-delay="0s">
											<div class="r-css">
												<img :src="item.img" />
												<div class="text-box">
													<div class="title">{{item.title}}</div>
													<br />
													<p>项目简介</p>
													<p>{{item.p1}}
													</p>
													<br />
													<p>主要功能</p>
													<p>{{item.p2}}
													</p>
												</div>

											</div>
										</div>
										<!-- </a> -->
										<!-- Optional controls -->
										<!-- <div class="swiper-pagination swiper-card-pagination" slot="pagination"></div> -->
										<div class="top4 swiper-button-prev swiper-card-button-prev swiper-button-white"
											slot="button-prev"></div>
										<div class="top4 swiper-button-next swiper-card-button-next swiper-button-white"
											slot="button-next"></div>
									</div>

								</el-col>

							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<!-- ///////////////////////////////////////////////////////////// -->
			<swiper :options="swiperOptionBanner" v-if="chooseTab==4" key="4">
				<swiper-slide v-for="(item,index) in swiperList5" :key="index">
					<div class="top-css1">
						<img class="img-bg" :src="item.bg" />
						<div class="top-css00">
							<div class="c1">
								<el-col :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 5, offset: 2 }"
									:lg="{ span: 3, offset: 0 }">
									<div class="c1-left">
										<div class="table">
											<div id="div" class="choosev" :class="item.ischose?'choosev2':''"
												v-for="(item, index) in itemData" :key="index"
												@click="menuClick(index)">
												<span>{{item.name}}</span>
											</div>
										</div>
									</div>
								</el-col>
								<el-col class="" :xs="{ span: 24 }" :sm="{ span: 15 }" :md="{ span: 10, offset: 2 }"
									:lg="{ span: 20, offset: 0 }">
									<div class="swiper-box">

										<!-- <a href="https://w.url.cn/s/A0Rz4Kv" target="_blank"> -->
										<div class="c1-right wow slideInUp" data-wow-delay="0s">
											<div class="r-css">
												<img :src="item.img" />
												<div class="text-box">
													<div class="title">{{item.title}}</div>
													<br />
													<p>项目简介</p>
													<p>{{item.p1}}
													</p>
													<br />
													<p>网站地址</p>
													<p>{{item.p2}}
													</p>
												</div>

											</div>
										</div>
										<!-- </a> -->

										<!-- Optional controls -->
										<!-- <div class="swiper-pagination swiper-card-pagination" slot="pagination"></div> -->
										<div class="top5 swiper-button-prev swiper-card-button-prev swiper-button-white"
											slot="button-prev"></div>
										<div class="top5 swiper-button-next swiper-card-button-next swiper-button-white"
											slot="button-next"></div>

									</div>

								</el-col>

							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>

	</div>
</template>

<script>
	import 'swiper/dist/css/swiper.css'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'



	export default {
		components: {
			swiper,
			swiperSlide

		},
		data() {

			return {
				chooseTab: 0,
				itemData: [{
						name: 'app',
						ischose: true
					},
					{
						name: '小程序',
						ischose: false
					},
					{
						name: '公众号',
						ischose: false
					},
					{
						name: '后台',
						ischose: false
					},
					{
						name: '官网',
						ischose: false
					}
				],
				swiperList1: [
				// 	{
				// 	img: require('@/assets/image/hzal1-8/hzal1_1.png'),
				// 	title: 'zxm商城APP',
				// 	p1: '淘宝客推广平台与各电子商务交易平台将逐步整合，方便用户推广，实现掌柜、多方共赢。淘宝优惠券对买家来说，领取之后下单，能获取优惠；对卖家来说，可以刺激买家下单，带来销量，提升转化。实际电商操作当中，zxm平台优惠券存在的意义，优惠券更加有利于拉动消费者二次进店，这个优惠券本身就是一个回头的流量入口。优惠券可以作为信息的载体，把你的优惠券送到顾客的手上。',
				// 	p2: 'zxmAPP是湖南德合信息科技有限公司在多年行业经验的基础上开发的一款淘宝等平台的优惠券的商城系统，搭建起卖家和买家的桥梁。分为淘宝商品的分类检索、拼多多平台的商品检索，实现多平台的连接。'
				// }, 
				{
					img: require('@/assets/image/hzal1-8/hzal1_2.png'),
					title: '新零售代理商系统',
					p1: ' 随着社会的不断发展和进步，传统的商户收银代理商系统已经不能适用快节奏的生活。商户收银代理商管理系统就解决了这一难题，随时都可以发展商户收银代理商。给商家们店铺日常管理带去了更多方便。生意拍档收银系统应运而生。这款app是德合信息为巨龙互联量身定制的代理商系统。',
					p2: '生意拍档商户收银代理商管理系统主要面向所有的商户门店。包含主流的付款收银方式，从根本上解决了管理高效、分析精确的要求。为实现高效的信息化管理方式和企业提高自身竞争能力定了坚实的基础。'
				}, {
					img: require('@/assets/image/hzal1-8/hzal1_3.png'),
					title: '中国电建 安建帮APP',
					p1: '安全帮APP是德合信息为水电八局打造的一款安全学习管理平台。是一款集知识库、表单应用、培训考试、网盘存储于一体的综合信息化项目管理工具。借助于灵活易用的简道云平台，旨在搭建满足企业个性化管理需求的应用表单，轻松实现数据记录、流程审批、手动签名、自定义模板打印、自动网盘存储、动态看板展示等一体化线上管理功能。知识库提供有海量企业实用的法规文本库，可随时查阅并直接用于表单数据进行调取，帮助企业依法施工生产、规范业务流程、促进团队协作、实现数据追踪！',
					p2: '在线答题、在线课堂、知识文库、审批流程、安全管理流程、培训管理、自定义流程、任务管理、在线商城、积分商城、多语言系统。'
				}, {
					img: require('@/assets/image/hzal1-8/hzal1_4.png'),
					title: '康雅集团 康雅医养APP',
					p1: '康雅集团是一家拥有医院、养老、健康管理、职业培训等众多实体业态的综合型企业。康雅医养APP是德合基于多个业态打造的互联网医疗服务平台。致力于连接医院、医生、用户提供从诊疗、体检、就医、养老、线上健康商城到医生视频直播的垂直医疗、消费养老服务，构建线上+线下+社群相互导流的三位一体的智慧医养平台。',
					p2: '健康医疗体系   社区养老体系    商城产品体系   医养联盟体系   医生直播知识付费体系    健康管理体系'
				}, {
					img: require('@/assets/image/hzal1-8/hzal1_5.png'),
					title: '凌诚星火APP',
					p1: '凌诚星火app合伙人系统,是德合信息为凌晨信息科技打造的一款代理商系统，以传统代理商转变为互联网的特性无限裂变，凌诚星火APP合伙人模式应运而生。\n有以下优势:\n1.入门门槛更低。一般公司最低5就可以做代理发展团队。\n2.发展区域和规模无限制，能者多劳，机具可以直接去由公司订货或者报团。\n3.所有的参与者直接对接公司总部。分润直发秒结，不用压分润，不用看上级代理脸色。\n4.返现和分润政策均一视同仁，分润、返现均由公司直接返到APP中，达到某一个标准就有某一个标准的利益，不存在传统代理模式的诸多弊端。',
					p2: '凌诚星火APP合伙人系统主要分为团队、展业、商店等页面。首页不仅可以查看政策信息，还方便合伙人展业发展客户。移动APP端可以直接查看团队管理、查看展业信息、商店还链接大牌运动品牌商店。让客户不仅可以发展客户，更可以消费购物。'
				}, {
					img: require('@/assets/image/hzal1-8/hzal1_6.png'),
					title: '伙头帮 美食APP',
					p1: '伙头帮为高级厨师们提供一个厨艺交流平台，大师们可以将自己的厨艺作品通过图文视频发布到平台进行展示或者售卖，用户可以在平台上找到优秀的厨师进行拜师学艺，大师们可以在平台上进行收徒管理。商户可以入驻到平台上进行发布招聘信息，也可以工用户查找周边门店，为线上的用户大师提供一个线下交流的场所，线上线下进行营销互动。',
					p2: '菜品上传、菜品学习、商家入驻、附近门店、求职招聘、交流论坛、积分营销、厨师入驻、用户拜师。'
				}, {
					img: require('@/assets/image/hzal1-8/hzal1_7.png'),
					title: '拉货客 物拉拉APP',
					p1: '拉货客平台，是一款智能化的对货车运送在线预约与拼单的智能化平台APP软件，软件支持智能化的在线地图导航和拼车操作，用户可以发出拉货拼车请求，就可以在线的等待司机接单，同时对个人的订单信息进行处理，同时对优惠券，账户余额等模块进行安全化开发，使得系统的安全性更高。',
					p2: '商户管理端、司机管理端、在线发单、单独订单、在线拼单、司机抢单、路线跟踪、实时定位、财务管理、佣金管理、营销推广'
				}, {
					img: require('@/assets/image/hzal1-8/hzal1_8.png'),
					title: '盼盼木业  新零售商城',
					p1: '盼盼木业新零售商城包含用户端、经销商端、以及后端管理系统，用户进入商城后畅意支付购买、会员积分、分销。经销商端包括：门店店长、总店店长、导购、安装师傅四个端口。后台管理端主要包括区运营中心和总平台端，区运营中心以区县为最小单位同时管理多个区县的具体运营内容和数据，总平台端管理整个平台的运营数据，进行平台运行的基础参数设置。',
					p2: '多商户商城  多门店商城  移动端商户管理 积分商城  三级分销  会员中心  门店导购员  维修预约  基础营销  高级营销'
				}],
				swiperList2: [{
					bg: require('@/assets/image/hzal2-2/hzal_bg.png'),
					img: require('@/assets/image/hzal2-2/hzal2_1.png'),
					title: '泰阳大药房 泰阳优选小程序',
					p1: '湖南泰阳大药房连锁有限公司是一家集药品零售、批发、药店经营为一体的综合医药流通企业。泰阳优选小程序旨在为客户提供便捷的线上买药体验。借助线下实体及分销列表模式，打造企业私域流量。',
					p2: '多门店商城  直播带货  团购  砍价  步数宝  会员卡  领券  预约  积分商城  三级分销',
					imgIcon: require('@/assets/image/hzal2-2/hzal2_icon1.png')
				}, {
					bg: require('@/assets/image/hzal2-2/hzal_bg.png'),
					img: require('@/assets/image/hzal2-2/hzal2_2.png'),
					title: '团课惠  教育O2O小程序',
					p1: '“团课惠”小程序是德合信息为善卷文化打造的教育类O2O平台。平台借鉴了美团+淘宝模式。平台邀请各类教育培训机构入驻，商家可自行发布自己的商品或者服务，借助小程序流量红利和平台多种营销工具进行推广及变现。',
					p2: '多商户 O2O  多商户商城  超级会员  三级分销  拼团  卡券  积分商城  限时秒杀  ......',
					imgIcon: require('@/assets/image/hzal2-2/hzal2_icon2.png')
				}, {
					bg: require('@/assets/image/hzal2-2/hzal2_3bg.png'),
					img: require('@/assets/image/hzal2-2/hzal2_3.png'),
					title: '工商银行积分商城',
					p1: '支持商家对会员进行积分营销。通过设定活动规则，能够实现：1. 发放礼品回馈用户，提高用户忠诚度。2. 低价搭售商品，有效提高销售额。更与会员积分体系相辅相成，完善积分闭环，提高用户黏性。',
					p2: '积分兑换  购买  订单  预览  查询  分享 礼品',

				}],
				swiperList3: [{
					img: require('@/assets/image/hzal3-3/hzal3_1.png'),
					title: '欧享创新生活  公众号',
					p1: '欧享创新生活该项目由德合信息科技有限公司专业技术团队研发，在反复测试试运营后正式上线的。随着经济发展，打开淘宝等购物平台，一搜排在前面的月销量都是成千上万的，刚开的小店，0信誉，0销量，0好评，拿什么跟人家竞争。所以，开淘宝店需要做任务订单是必然的，尤其是对一些新开的店铺来说，做任务订单必不可少。欧享生活订单任务的上线，也标志着向平台化迈向了重要的一步。',
					p2: '欧享生活订单任务平台有五个功能模块。分为任务大厅、发布新任务、我发布的任务、我领取的任务、申请佣金提现，操作简单化。派单人与接单人都在一个类目下，既能接单，也能派单赚钱。..'
				}, {
					img: require('@/assets/image/hzal3-3/hzal3_2.png'),
					title: '湘潭大学本科招生 公众号',
					p1: '湘潭大学本科招生平台是湘大新生入学必备的公众号，项目为湘潭大学招生办牵头，德合信息负责最终设计及制作。平台主要功能为两大板块：①校园简介：通过文章、图册、VR全景等方式开展②招生查询：学科查询、历年分数线、录取结果等，需要对接多个校内系统。',
					p2: '校园简介  VR导游  新生指南 分数线查询  录取结果查询  学科介绍  招生计划  校园相册 '
				}, {
					img: require('@/assets/image/hzal3-3/hzal3_3.png'),
					title: '步步高集团 步步高HR',
					p1: '步步高HR公众号定位为服务步步高内部员工的人力资源综合平台。包含人才招聘、学习发展、员工服务三大板块，从步步高人才招聘官网到企业全景，从员工建言献策到员工关爱，充分展现了企业风采，实现了社会和校园招聘的全过程。',
					p2: '社会招聘  校园招聘    VR全景     建言献策   员工驱动    员工关爱   新人专区    转正考核  培训报名  投诉反馈',

				}],

				swiperList4: [{
					bg: require('@/assets/image/hzal5-4/hzal5_1bg.png'),
					img: require('@/assets/image/hzal5-4/hzal5_1.png'),
					title: '勤凯蓄电池智能监控云平台',
					p1: '勤凯轨道交通蓄电池检测云平台主要面向轨道交通蓄电池进行实时监测，智能预警，状态修，让管理维护人员实时了解蓄电池状态，及时解决异常状态电池问题，通过大数据算法智能分析蓄电池的使用状态、使用寿命。目前勤凯已经在长沙地铁等多地区地铁线路进行电池监测，未来都会进行上云服务，将会让管理更便捷、精准。',
					p2: '硬件检测、安全通讯、数据分析、实时数据管理、智能预警、在线检修、数据管理、多端口角色管理'
				}, {
					bg: require('@/assets/image/hzal5-4/hzal5_2bg.png'),
					img: require('@/assets/image/hzal5-4/hzal5_2.png'),
					title: '湘潭大学教职工考勤系统',
					p1: '湘潭大学考勤系统，借助湘大现有的门闸系统人员进出数据，通过智能的数据汇总统计，进行考勤的统计分析，判断教职工上班考勤情况。湘大教职工无需进行打卡考勤，正常上下班即可分析用户考勤情况；同时平台针对不同季节不同节日设定不同的考勤规则，用户可以在平台发起请假和相关审批工作，目前平台服务与湘大3000教职工。',
					p2: '硬件数据收集、安全通讯、数据分析、实时数据管理、考勤数据分析、在线请假审批、会议办公数据联动、多端口角色管理'
				}, {
					bg: require('@/assets/image/hzal5-4/hzal5_3bg.png'),
					img: require('@/assets/image/hzal5-4/hzal5_3.png'),
					title: '巨达人力招聘云平台',
					p1: '巨达智慧招聘SaaS云平台是一个开放式招聘云平台服务系统，可实现不同市区的招聘管理，不同市区可以管理自己区域下的企业招聘及用户，真正的SaaS云平台管理系统，以总平台端—各市区级端—企业端—用户端建立一条招聘服务体系，让区级政府招聘管理更高效，企业招聘人才更方便个人。',
					p2: '在线求职招聘、在线招聘会、智能简历匹配、大数据分析、实时数据管理、数据管理、多端口角色管理'
				}, {
					bg: require('@/assets/image/hzal5-4/hzal5_4bg.png'),
					img: require('@/assets/image/hzal5-4/hzal5_4.png'),
					title: '长沙文创产业园信息管理系统',
					p1: '通过长沙市文化创意产业信息平台的建设，推动现代文化产业体系建设，实现科学高效的电子政务管理体系，解决服务“最后一公里”问题，全面整合文化产业资源，更高效便捷提供项目管理、发布、监管及相关服务，提高决策准确性，减少风险，加快文化产业发展信息的传播，有效实现文化产业信息资源的共享。',
					p2: '企业管理、园区管理、项目申报审批、投融资需求、实时数据管理、人才库管理、多端口角色管'
				}],

				swiperList5: [{
					bg: require('@/assets/image/hzal4-4/hzal4_bg1.png'),
					img: require('@/assets/image/hzal4-4/hzal4_1.png'),
					title: '超级文和友官网',
					p1: '文和友官网实现了企业宣传，资讯传递，在线地图导航，线上包厢预定一体化，充分展示企业形象的同时，多种服务功能为提升用户体验。',
					p2: '文和友官网：www.cjwenheyou.com'
				}, {
					bg: require('@/assets/image/hzal4-4/hzal4_bg2.png'),
					img: require('@/assets/image/hzal4-4/hzal4_2.png'),
					title: '世象知识产权网',
					p1: '平台以“帮助用户建立核心技术壁垒”和“把创新成果转化成财富”为使命，依托互联网、大数据、人工智能等技术手段，为用户精准、高效提供知识产权的检索分析、研发数据支持、知识产权的布局、挖掘、保护到商业化一站式全链条的服务。',
					p2: '世象知识产权官网：http://unitotem.com/'
				}, {
					bg: require('@/assets/image/hzal4-4/hzal4_bg3.png'),
					img: require('@/assets/image/hzal4-4/hzal4_3.png'),
					title: '湘潭大学官网',
					p1: '湘潭大学官网整理采用红色作为主色调，体现伟人故里革命圣地的文化底蕴。此外德合信息还为湘潭大学定制了学工处官网、后勤处官网、继续教育学院官网、信工院官网、王先友实验室官网等一些列网站......',
					p2: '湘潭大学官网：https://www.xtu.edu.cn/'
				}, {
					bg: require('@/assets/image/hzal4-4/hzal4_bg4.png'),
					img: require('@/assets/image/hzal4-4/hzal4_4.png'),
					title: '中国废塑料协会展会网',
					p1: 'ChinaReplas2018中国国际塑料循环展会网，网站集成展会信息、观众登记、展商报名、邮件群发等功能。采用新型网页自适应技术，能够在PC端/手机端/平板端自由切换。',
					p2: '中国废塑料协会会展官网：http://www.replas.org.cn'
				}],


				showBigImg: false,
				bigImgSrc: null,
				swiperOptionBanner: {
					autoHeight: true,
					slidesPerView: 1, //显示数量
					loop: true,
					// autoplay: {
					// 	delay: 8000,
					// 	disableOnInteraction: false //点击切换后 停止继续自动播放
					// },
					speed: 1000,
					effect: 'fade', //渐变
					pagination: {
						el: '.swiper-card-pagination',
						clickable: true,
					},
					observer: true,
					navigation: {
						nextEl: '.swiper-card-button-next',
						prevEl: '.swiper-card-button-prev',
					}


				},
				swiperOptionCard: {
					slidesPerView: 1, //显示数量
					spaceBetween: 10,
					centeredSlides: true, //居中slide
					loop: true,
					// autoplay: { //自动播放
					// 	delay: 2000,
					// 	disableOnInteraction: false
					// },
					speed: 1000,

					pagination: {
						el: '.swiper-card-pagination',
						clickable: true,
					},
					observer: true,
					navigation: {
						nextEl: '.swiper-card-button-next',
						prevEl: '.swiper-card-button-prev',
					}
				},

			}
		},
		mounted() {

		},
		methods: {
			menuClick(index) {
				this.chooseTab = index
				for (var i = 0; i < this.itemData.length; i++) {
					this.itemData[i].ischose = index == i ? true : false
				}
				// console.log("this.chooseTab: ", this.chooseTab);
			},


		}
	};
</script>

<style lang="scss">
	.img-ph {
		display: none;
	}

	.swiper-box {
		position: relative;
		padding: 0 50px;

		.in-box {
			position: initial;

		}
	}

	// banner swiper
	.carousel-banner {
		.swiper-pagination {
			display: flex;
			align-items: center;
			position: absolute;
			width: auto;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			background-color: rgba(0, 0, 0, 0.1);
			border-radius: 11px;
			padding: 6px 16px;
		}

		.swiper-pagination-bullet {
			width: 6px !important;
			height: 6px !important;
			background: #fff !important;
			opacity: 0.8;
		}

		.swiper-pagination-bullet-active {
			background: transparent !important;
			border: 2px solid #fff !important;
		}
	}

	.swiper-pagination {
		display: flex;
		align-items: center;
		position: absolute;
		width: auto;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 11px;
		padding: 6px 16px;
	}

	.swiper-pagination-bullet {
		width: 6px !important;
		height: 6px !important;
		background: #fff !important;
		opacity: 0.8;
	}

	.swiper-pagination-bullet-active {
		background: transparent !important;
		border: 2px solid #fff !important;
	}



	#particles-js {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}

	#particles-content {
		position: absolute;
		width: 100%;
		top: 0%;
		bottom: 10%;

	}


	#particles-content .big-title {
		width: 100%;
		height: 70%;
		// background-image: url(../../assets/images/top_banner2X_title.png);
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: 50% 50%;
	}


	#particles-content .desc-title {
		padding-top: 2%;
		text-align: center;
		color: rgba(255, 255, 255, .8);
		font-size: 32px;
		height: 30%;
	}

	#particles-content .desc-title-top {
		padding-top: 2%;
		text-align: center;
		color: rgba(255, 255, 255, .8);
		font-size: 62px;
		font-weight: 800;
		height: 50%;
	}

	.top-page {
		background-size: cover;
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		width: 818;
		height: 869;
		overflow: hidden;
	}

	.all-page {
		background-size: cover;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
</style>

<style lang="scss" scoped>
	.carousel-banner {
		width: 100%;
		position: relative;

		.swiper-container {
			height: 100%;
			width: 100%;
		}

		.swiper-slide .banner-img {
			width: 100%;
		}

		.swiper-button-prev,
		.swiper-button-next {
			outline: none;
			height: 30px;
		}
	}


	.bgbox-white {
		padding: 50px 0;
		overflow: hidden;
		background: #fff;
	}

	.bgbox-gray {
		padding: 50px 0;
		overflow: hidden;
		background: #f7f7f7;
	}

	.module-title {
		text-align: center;
		font-size: 26px;
		font-weight: bold;
		line-height: 1;
		padding-bottom: 30px;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.top-css {
		background-color: #080B17;
		color: #fff;
		position: relative;
		height: 800px;

		.img-bg {
			position: absolute;
			bottom: 0%;
			width: 100%;
			height: 100%;

		}

		.c1 {
			position: absolute;
			top: 25%;
			left: 0%;
			right: 0%;
			color: #FFFFFF;

			.c1-left {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.choosev {
					font-size: 18px;
					padding: 8px 30px;
					text-align: center;
					color: #ffffff;
					margin: 30px 0;
				}

				.choosev2 {
					border-radius: 50px;
					border: 1px solid #ffffff;
				}
			}

			.c1-right {
				.r-css {
					display: flex;
					flex-direction: row;

					img {
						height: 350px;
					}

					.text-box {
						margin-left: 20px;

						.title {
							margin-top: -10px;
							font-weight: 700;
							font-size: 40px;
						}

						p {
							word-break: break-all;
							white-space: pre-line;
							font-size: 18px;
						}

						img {
							width: 100px;
							height: 100px;
						}
					}
				}

			}
		}

	}

	.top-css1 {
		background-color: #080B17;
		position: relative;
		height: 800px;

		.img-bg {
			width: 100%;
			height: 100%;
		}

		.top-css00 {
			position: absolute;
			top: 0%;
			left: 0%;
			right: 0%;
			height: 800px;

			.c1 {
				position: relative;
				top: 25%;
				color: #FFFFFF;

				.c1-left {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.choosev {
						font-size: 18px;
						padding: 8px 30px;
						text-align: center;
						color: #ffffff;
						margin: 30px 0;
					}

					.choosev2 {
						border-radius: 50px;
						border: 1px solid #ffffff;
					}
				}

				.c1-right {
					.r-css {
						display: flex;
						flex-direction: row;

						img {
							// margin-top: 20px;
							height: 350px;
						}

						.text-box {
							margin-left: 20px;

							.title {
								margin-top: -10px;
								font-weight: 700;
								font-size: 40px;
							}

							p {
								word-break: break-all;
								white-space: pre-line;
								font-size: 18px;
							}

							img {
								width: 100px;
								height: 100px;
							}
						}
					}

				}
			}

		}
	}



	// 移动端css
	@media screen and (max-width: 767px) {
		.img-pc {
			display: none;
		}

		.img-ph {
			display: block;
		}

		.swiper-box {
			position: relative;
			padding: 0 10px;

			.in-box {
				position: initial;

			}
		}


		.swiper-button-prev,
		.swiper-button-next {
			// display: none;
			// visibility: hidden;
			outline: none;
			height: 20px !important;
			position: absolute;
			top: 14%;
		}
		.top1 {
			top: 18%;
		}

		.top2 {
			top: 20%;
		}

		.top3 {
			top: 17%;
		}

		.top4 {
			top: 22%;
		}

		.top5 {
			top: 30%;
		}

		.top-css {
			background: linear-gradient(180deg, #080B17 0%, #11162C 91.98%);
			color: #fff;
			position: relative;
			height: 1600px;

			.img-ph {
				width: 100%;
			}

			.top-css00 {
				position: absolute;
				top: 4%;
				left: 0%;
				right: 0%;
				height: 1200px;

				.c1 {
					position: relative;
					top: 0%;
					left: 0%;
					right: 0%;
					color: #FFFFFF;

					.c1-left {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.table {
							width: 100%;
							display: flex;
							flex-direction: row;

						}

						.choosev {
							font-size: 14px;
							color: #0C0C0C;
							width: 20%;
							padding: 8px 0px;
							margin-bottom: 20px;
							text-align: center;
							align-items: center;
							color: #ffffff;

						}

					}

					.c1-right {
						.r-css {
							display: flex;
							flex-direction: column;

							img {
								width: 80%;
								height: 280px;
								object-fit: contain;
								margin-top: 10%;
								margin-left: 10%;

							}



							.text-box {
								margin-left: 0px;

								.title {
									margin-top: 40px;
									font-size: 26px;
								}

								p {
									line-height: 210.5%;
									font-size: 18px;
									word-break: break-all;
								}
							}

						}

					}
				}

			}




		}

		.top-css1 {
			background-color: #080B17;
			position: relative;
			height: 1200px;

			.img-bg {
				width: 100%;
				height: 1200px;
				object-fit: cover;
			}

			.top-css00 {
				position: absolute;
				top: 5%;
				left: 0%;
				right: 0%;
				height: 1200px;

				.c1 {
					position: relative;
					top: 0%;
					left: 0%;
					right: 0%;
					color: #FFFFFF;

					.c1-left {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.table {
							width: 100%;
							display: flex;
							flex-direction: row;

						}

						.choosev {
							font-size: 14px;
							color: #0C0C0C;
							width: 20%;
							padding: 8px 0px;
							margin-bottom: 20px;
							text-align: center;
							align-items: center;
							color: #ffffff;

						}

					}

					.c1-right {
						.r-css {
							display: flex;
							flex-direction: column;

							img {
								width: 80%;
								height: 280px;
								object-fit: contain;
								margin-top: 5%;
								margin-left: 10%;

							}



							.text-box {
								margin-left: 0px;

								.title {
									margin-top: 40px;
									font-size: 26px;
								}

								p {
									line-height: 210.5%;
									font-size: 18px;
									word-break: break-all;
								}
							}

						}

					}
				}

			}

		}



		.module-title {
			text-align: center;
			font-size: 26px;
			font-weight: bold;
			line-height: 1;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
	}


	// 991
	@media screen and (max-width: 991px) {
		.flex-row {
			display: block;
		}
	}
</style>
